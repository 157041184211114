@media only screen and (max-width: 725px) {
    .main-content {
        height: calc(100% - 60px) !important;
    }
}

@media only screen and (max-width: 450px) {
    .main-content {
        height: calc(100% - 100px) !important;
    }

    .top-content-wrapper {
        .top-sub-wrapper {
            padding: 0 !important;
            margin-right: 0px;
            font-size: 10px !important;
            display: contents !important;

            .label-wrapper {
                padding: 0 7px !important;

                .label-div {
                    padding: 0 5px !important;
                }
            }
        }

        .admin-logout-wrapper {
            .header-com-name {
                padding: 0 0px !important;
                font-size: 12px !important;
            }

            .login-button {
                padding: 0 5px !important;

                img {
                    height: 16px !important;
                    width: 16px !important;
                }
            }
        }

        .admin-top-content {
            .header-com-name {
                height: 50px;
                right: 30px !important;
                line-height: 30px !important;
            }
        }


    }


}