// CSS Reset, comment out if not required or using a different module
// @import 'minireset.css/minireset';
// @use 'igniteui-angular/theming' as *;
// @use '/src/app/variables' as *;
// @use '/src/app/app-layout';
// @include core();
// @include typography();
// @include theme(
//     $palette: $palette,
//     $schema: $schema
// );

// /* autoprefixer grid: on */
// html,
// body {
//     height: 100%;
//     overflow: hidden;
//     margin: 0;
// }

// @font-face {
//     font-family: 'Pacifico';
//     font-style: normal;
//     font-weight: 400;
//     src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
//     font-display: swap;
// }
/* You can add global styles to this file, and also import other style files */

/**======================================================================
=========================================================================
Template Name: Gradient Able Admin Template
Author: Codedscss/themes
supports: https://codedscss/themes.com/submit-ticket/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */

// Theme Font
@import 'scss/themes/font/theme-font';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';

// main framework
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'scss/settings/custom-variables';
@import 'scss/settings/theme-variables';

// bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/utilities/api';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import 'scss/themes/general';
@import 'scss/themes/generic';
@import 'scss/themes/minixs/function';

// icon
@import 'scss/themes/font/datta-icon';
@import 'scss/themes/font/feather';
@import 'scss/themes/font/fontawesome';
@import 'scss/themes/font/flaticon';

// component
@import 'scss/themes/component/component';

// theme layouts
@import 'scss/themes/layouts/menu-lite';
@import 'scss/themes/layouts/menu-ng';

// dashboard
@import 'scss/themes/pages/dashboard.scss';
@import 'scss/themes/pages/authentication';

@import 'scss/themes/chat/chat';

// responsive
@import 'scss/themes/pages/responsive.scss';

// custom
@import 'scss/themes/custom';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import "@mescius/activereportsjs/styles/ar-js-ui.css";
@import "@mescius/activereportsjs/styles/ar-js-viewer.css";

html,
body {
  height: 100%;
  width: 100%;
}

// Grid Row Size Height
.grid__wrapper {
  --ig-size: var(--ig-size-small);
}

// Igx Grid Toolbar (Ex : Add New Row Button)
.igx-grid-toolbar {
  border-bottom: none;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}

// Igx Grid
.igx-grid__tbody {

  // .igx-banner__message {
  //   display: none;
  // }
  .igx-grid__tr--selected .igx-grid__td--selected {
    background: #0078d7 !important;
  }

  igc-trial-watermark {
    display: none;
  }

  .igx-grid__tr--selected // .igx-grid__td--selected,

  // .igx-grid__td--active
    {
    color: #FFF !important;
    background-color: #0078D7 !important;
    --cell-active-border-color: transparent !important;
  }

  .igx-grid__td--active {
    box-shadow: none !important;
  }

  .igx-checkbox__composite {
    border: 1px solid #000 !important;
    border-radius: 5px !important;
  }
}

.igx-grid-th__title {
  color: #000 !important;
  font-weight: 400 !important;
}

igx-grid-header-row {
  .igx-checkbox__composite {
    border: 1px solid #000 !important;
    border-radius: 5px !important;
  }
}

igx-checkbox,
igx-radio {
  --fill-color: #0078D7 !important;
  --fill-color-hover: #0078D7 !important;
  --fill-hover-border-color: #0078D7 !important;
}

.igx-grid-thead__wrapper {
  background-color: #C1D9F0 !important;
}

// .igx-grid {
//   --cell-edited-value-color: #FFF;
// }

// All Ignite-ui Buttons
.igx-button--flat {
  // background-color: #F0F6FE !important;
  background-color: #C1D9F0 !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  font-weight: lighter !important;
  font-size: 13px !important;
}

.igx-button--flat:hover {
  // background-color: #EEEB85 !important;
  // color: #000 !important;
  background-color: #034b8e !important;
  color: #FFFFFF !important;
  // border: 1px solid #000 !important;
  // font-weight: lighter !important;
  // font-size: 13px !important;
}

mat-tab-group {
  border: 1px solid #BFDBFF;
  border-radius: 8px;

  mat-tab-header {
    height: 30px;
    margin: 0 0 10px 0;

    .mat-mdc-tab-labels {
      background: #BFDBFF;
      border-radius: 5px 5px 0px 0;
      height: 100%;

      .mdc-tab {
        height: 100%;
        padding: 0 10px;
        flex-grow: 0 !important;

        .mdc-tab-indicator {
          display: none !important;
        }

        &--active {
          background: #fff;
          border-radius: 7px 5px 0 0;
        }
      }
    }
  }
}

.igx-checkbox {
  .igx-checkbox__composite {
    border-radius: 5px !important;
    border: 1px solid #000 !important;
  }
}

.igx-combo {
  .igx-combo__clear-button {
    display: none;
  }

  input {
    font-size: 13px !important;
  }
}

igx-date-picker {
  input {
    font-size: 13px !important;
  }
}

igx-tabs.igx-page-tab {
  border: 1px solid #BFDBFF !important;
  border-radius: 8px !important;

  .igx-tabs__header-content {
    height: 30px;
    margin: 0 0 10px 0;
    background: #BFDBFF;
    border-radius: 5px 5px 0px 0;

    .igx-tab-header.igx-tabs__header-item--selected {
      background: #fff;
      border-radius: 7px 5px 0 0;
      color: #000 !important;
      font-weight: 600 !important;
    }

    .igx-tab-header {
      height: 100%;
      padding: 0 10px;
      flex-grow: 0 !important;
      background: transparent;
    }
  }
}

app-admin {
  overflow: hidden !important;
}

igx-tabs {
  // --indicator-color: var(--igx-tabs-indicator-color, hsla(var(--ig-primary-800-contrast), var(--ig-primary-a))) !important;
  --indicator-color: #fff !important;
}

.stack_layout {
  display: inline-grid;
  margin: 10px 10px;
  width: 100%
}

::ng-deep {
  igx-grid {
    igx-grid-cell {
      max-width: 100% !important;
      flex-basis: 100% !important;

      >div {
        width: 100%;
      }

      .stack_layout {
        margin: 10px 10px !important;
        width: 100%;
      }
    }
  }

  .form-wrapper {

    igx-date-picker,
    igx-select {
      igx-input-group.igx-input-group {
        border: 1px solid #aaa;
        border-radius: 5px;
        padding: 3px;
        background: #eaf2fb;

        .igx-input-group__bundle {
          height: auto;

          .igx-input-group__line {
            display: none;
          }

          input {
            height: auto;
          }

          .igx-select__toggle-button {
            background-color: transparent !important;
          }
        }

        .igx-input-group__bundle::after {
          border: none !important;
        }
      }
    }

    igx-chip:focus {
      .igx-chip__item {
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        background: #fff;
        color: #1A58D0;
        border: 1px solid #1A58D0;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin: 5px;
      }
    }

    igx-chip:hover {
      .igx-chip__item {
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        background: #1A58D0;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin: 5px;
      }
    }

    igx-chip {
      .igx-chip__item {
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        background: #fff;
        color: #1A58D0;
        border: 1px solid #1A58D0;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin: 5px;
      }

      .igx-chip__item.igx-chip__item--selected {
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        background: #1A58D0;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin: 5px;
      }
    }

    igx-simple-combo,
    igx-combo {
      height: 30px;
      margin-bottom: 10px;

      igx-input-group {
        background: #eaf2fb;
        height: 100%;

        .igx-input-group__wrapper {
          height: 100%;

          .igx-input-group__bundle {
            height: 100%;

            div {
              background: #eaf2fb;
              height: 100%;

              input {
                height: 100%;
              }

              igx-suffix {
                background: #eaf2fb;

                &.igx-combo__clear-button {
                  display: none;
                }
              }
            }
          }

        }
      }
    }

    igx-input-group.igx-input-group {
      border: 1px solid #aaa;
      border-radius: 5px;
      padding: 3px;
      background: #eaf2fb;

      .igx-input-group__bundle {
        height: auto;

        .igx-input-group__line {
          display: none;
        }

        .igx-input {
          height: auto;
        }
      }

      .igx-input-group__bundle::after {
        border: none !important;
      }
    }
  }

  .cdk-overlay-pane:has(mat-dialog-container) {
    position: relative !important;
    overflow-y: auto !important;
  }
}

// Ignite UI Grid CSS
.igx-drop-down__item--selected.igx-drop-down__item--focused,
.igx-drop-down__item--selected {
  background: #bfdbff !important;
}

// Filter layout in list page
.filter-pannel-wrapper {
  igx-expansion-panel.my-expansion-panel {
    border: 2px solid #e1e1e1 !important;
    border-radius: 15px !important;
    margin: 5px 2px 20px 2px !important;

    igx-expansion-panel-header {
      .igx-expansion-panel__header-inner {
        display: flex;
        background: #e1e1e1 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
      }
    }
  }
}

.alert {
  padding: 0px;
  color: red;
}

.png-icon:hover {
  cursor: pointer;
}

/* #region Dialog Box Close Button */
.close-button {
  position: absolute;
  float: right;
  top: 0;
  right: 0;
  line-height: 1;
  padding: 0px 4px;
}

.toaster-wrapper {
  z-index: 1050 !important;
  position: relative;
}

.close-icon:hover {
  color: black;
}

::ng-deep .icon-outside .close-button {
  float: right;
  top: -52px;
  right: -52px;
}

::ng-deep {
  .close-button .material-icons {
    font-size: 16px !important;
    padding: 0 !important;
  }
}

::ng-deep .icon-outside .mat-dialog-container {
  overflow: unset
}

@media (width < '410px') {
  .company-list-wrapper>div {
    margin-right: 0 !important;
  }
}

// ::ng-deep {

//   // @media screen and (max-width:1024px) {    
//   .responsive-grid-background {
//     .igx-grid__tbody-content {
//       .igx-grid-row .igx-grid__tr--even {
//         background: #DFDFDF !important;
//       }
//     } 
//   }

//   // }
// }
@media screen and (max-width:1024px) {
  .responsive-grid-background {
    igx-grid-row:nth-child(even) {
      // background: #DFDFDF !important;
      background-color: #DFDFDF;
    }
  }

  full-calendar {
    @media screen and (max-width:1024px) {
      .fc-header-toolbar {
        display: block !important;

        .fc-toolbar-chunk:not(:last-child) {
          float: left;
        }

        .fc-toolbar-chunk:first-child {
          margin-right: 10px;
        }

        .fc-toolbar-chunk:last-child {
          float: right;
        }

        .fc-toolbar-chunk {
          font-size: 10px !important;
        }
      }
    }
  }

  .form-body-wrapper {
    .form-top-wrapper {
      .page-label-wrapper {
        display: flex;
        width: 100% !important;

        .page-header-label-div {
          display: table-cell !important;
          font-weight: 800 !important;
          padding: 0 5px !important;
        }

        .page-header-value-div {
          display: table-cell !important;

          &.warning {
            color: red;
          }
        }
      }

      .form-top-title-wrapper {
        .top-title-header {
          line-height: 30px !important;
        }
      }

      .form-toolbar-btn-wrapper {
        padding: 3px !important;

        .context-menu-btn {
          padding: 0 20px;
          min-height: 25px;
        }

        img {
          height: 25px !important;
          width: 25px !important;
        }
      }
    }
  }

  .login-button {
    right: 3px !important;
    top: 0px !important;

    img {
      height: 20px !important;
      width: 20px !important;
    }
  }
}


.form-body-wrapper {
  .form-top-wrapper {
    .page-label-wrapper {
      display: flex;
      width: 100% !important;

      .page-header-label-div {
        display: table-cell !important;
        font-weight: 800 !important;
        padding: 0 5px !important;
      }

      .page-header-value-div {
        display: table-cell !important;

        &.warning {
          color: red;
        }
      }
    }

    .form-top-title-wrapper {
      .top-title-header {
        line-height: 70px;
      }
    }

    .form-toolbar-btn-wrapper {
      padding: 3px;

      .context-menu-btn {
        padding: 0 20px;
        min-height: 25px;
      }

      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}


/* #endregion Dialog Box Close Button*/

/* Display checkbox center in the forms */
.checkbox-center {
  vertical-align: super;
}

/* Display checkbox in the middle in header */
.header-checkbox-align-middle {
  vertical-align: middle;
}

/* Custome Hierarchical Grid */
.parent-row.header-row {
  background: #C1D9F0;
}

.note-parent-row {
  background: #C1D9F0;
}

.parent-row.expanded-row {
  padding: 10px;

  .child-row {
    display: inline-flex;
  }
}

.selected {
  background-color: #0078D7;
  // background-color: #a0b7ff96;
  // color: #fff;
  color: #151414;
}

.parent-row {
  display: block;
  overflow: auto;
  width: 100%;
  border-bottom: 1px solid #aaa3;

  .field-text {
    font-size: 13px;
    line-height: 32px;
    // padding-left: 10px;
  }

  .chk-spacing {
    margin-left: 10px;
  }

  .column {
    float: left;
    padding: 0px;
    padding-left: 3px;
    padding-bottom: 5px;

    igx-checkbox {
      margin-top: 5px;
    }
  }

  .res-col-header {
    display: none;
  }

  .plus-column {
    float: left;
    width: 40px;
    text-align: center;
  }

  .header-row {
    display: block;

    .column {
      color: #000;
    }
  }

  .note-header-row {
    display: block;

    .column {
      color: #000;
    }
  }

  .header-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    // padding-left: 10px;
  }

  .child-group-wrapper {
    margin-left: 20px;
    // margin-right: 30px;
    width: calc(100% - 20px);
    box-shadow: 0px 0px 11px 0px #aaa;
    border-radius: 3px;
    overflow: auto;

    .child-group {
      width: fit-content;
      width: 100%;
      color: #424242;
    }
  }

  .child-row {
    width: 100%;
    display: block;
    border-bottom: 1px solid #aaa3;

    .column {
      padding-left: 10px;
    }
  }

  /* House Note Grid Style */
  .child-row.header-row {
    background: #C1D9F0;
  }

  /* House Note Grid Style */
  .note-header-row {
    background: #C1D9F0;
  }

}

.hou-address-detail,
.house-note-item-edit {
  display: none;
}


@media screen and (max-width:1024px) {
  .hou-address-detail {
    display: block !important;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #C1D9F0;
  }

  .res-col-header {
    display: block !important;
    font-weight: 600;
    float: left;
    line-height: 32px;
  }

  .parent-row .child-group-wrapper {
    margin-left: 25px;
    margin-right: 0;
    width: calc(100% - 25px);
  }

  .child-group-wrapper {

    .child-group {
      .child-row {
        color: #424242;

        .column {
          background-color: #FAFAFA;
        }
      }
    }

    .child-group:nth-child(odd) {
      .child-row {
        color: #424242;

        .column {
          // background-color: #BFDBFF;
          background-color: #dfdfdf;

        }
      }
    }
  }

  .child-row {
    display: block !important;

    .colomn {
      igx-checkbox {
        padding-left: 5px;
      }
    }

    .column:first-child {
      padding-top: 5px !important;
    }
  }

  .selected {
    // color: #fff !important;
    color: #000 !important;
    background-color: #a0b7ff96;

    .column:first-child {
      // border-top: 2px solid #0078D7;
      border-top: 2px solid #6750A4 !important;
      border-radius: 5px 5px 0px 0px;
    }

    .column:last-child {
      // border-bottom: 2px solid #0078D7;
      border-bottom: 2px solid #6750A4 !important;
      border-radius: 0px 0px 5px 5px;
    }

    .column {
      // background-color: #0078D7 !important;
      background-color: #a0b7ff96 !important;
      // border-right: 2px solid #0078D7;
      // border-left: 2px solid #0078D7;
      border-right: 2px solid #6750A4 !important;
      border-left: 2px solid #6750A4 !important;
    }
  }

  .column {
    padding-left: 10px !important;
  }

  .field-text {
    padding-left: 10px;
  }

  /* House Note Grid Style */
  .note-header-row {

    .col-notes,
    .col-ho-internal-comments-notes {
      background-color: #C1D9F0;
    }
  }

  /* House Note Grid Style */
  .note-header-row> :not(:first-child) {
    display: none !important;
  }

  .house-note-item-edit {
    display: block !important;
  }

}

::ng-deep {
  .external-wrapper-note-item {
    textarea {
      font-size: 10px;
    }

    .igx-overlay__content--modal {
      width: 95% !important;
    }
  }
}