/**  =====================
      button css start
==========================  **/
@import '../minixs/button';
.btn-page {
  .btn {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .btn-group-vertical,
  .btn-group {
    .btn {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-light-#{$color} {
    background: shift-color($value, $soft-bg-level);
    color: $value;
    border-color: shift-color($value, $soft-bg-level);
    .material-icons-two-tone {
      background-color: $value;
    }

    &:hover {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }

    &.focus,
    &:focus {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }
  }

  .btn-check:active,
  .btn-check:checked {
    + .btn-light-#{$color} {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }
  }

  .btn-link-#{$color} {
    background: transparent;
    color: $value;
    border-color: transparent;
    .material-icons-two-tone {
      background-color: $value;
    }
    &:hover {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }

    &.focus,
    &:focus {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }

  .btn-check:active,
  .btn-check:checked {
    + .btn-link-#{$color} {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }
}
